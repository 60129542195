import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Card from "../components/ui/card";
import Title from "../components/ui/title";
import Section from "../components/layout/section";
import EmailForm from "../components/emailForm";
import * as styles from "./contacts.module.scss";
import { SEOv2 } from "../components/seov2";

const Contacts = () => (
  <Layout>
    <Section type="green" className={styles.firstSectionContainer}>
      <span className={styles.heading}>Свържете се с нас</span>
    </Section>
    <Section type="white" className={styles.sectionContainer}>
      <Title className={styles.titleMobile}>Контакти</Title>
      <div className={styles.s2CardContainer}>
        <Card
          icon="phone"
          title="+359 888 326 203"
          className={styles.cardTitle}
        />
        <Card
          icon="email"
          title="info@limazulu.eu"
          className={styles.cardTitle}
        />
        <Card
          icon="address"
          title="ул. Продан Таракчиев 10, София"
          className={styles.cardTitle}
        />
      </div>
    </Section>
    <Section type="nearly-black" className={styles.sectionContainer}>
      <Title inverted={true} className={styles.titleMobile}>
        Пишете ни тук
      </Title>
      <EmailForm />
    </Section>
  </Layout>
);

export default Contacts;

export const Head = () => <SEOv2 title="Контакти" />;

import * as React from "react"
import * as EmailFormStyles from "./emailForm.module.scss";
import Button from "./ui/button"

const EmailForm = () => (
  <div className={EmailFormStyles.wrapper}>
    <form className={EmailFormStyles.form} name="contact" method="POST" data-netlify="true" action="/contact" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact" />
        <label className={EmailFormStyles.label}>Име</label>
        <input className={EmailFormStyles.input} required type="text" name="name" />
        <label className={EmailFormStyles.label}>Имейл</label>
        <input className={EmailFormStyles.input} required type="email" name="email" />
        <label className={EmailFormStyles.label}>Съобщение</label>
        <textarea className={EmailFormStyles.textarea} required name="message"></textarea>
        <Button className={EmailFormStyles.submit} inverted={true} type="submit" title='Изпрати' />
    </form>
  </div>
)

export default EmailForm

import * as React from "react"
import * as CardStyles from "./card.module.scss";
import classNames from "classnames";
import { svgs } from '../../components/svgs';

const Card = ({ title, desc, icon, className }) => {
    const containerStyles = classNames(
        CardStyles.wrapper,
        className
    );
    const Icon = svgs[icon];

    return (
        <div className={containerStyles}>
            <div className={CardStyles.icon}><Icon /></div>
            <div className={CardStyles.title}>
                {title}
            </div>
            <div className={CardStyles.desc}>
                {desc}
            </div>
        </div>
    )
}

export default Card

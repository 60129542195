import Research from '../assets/svg/research.svg';
import Build from '../assets/svg/build.svg';
import Design from '../assets/svg/design.svg';
import Install from '../assets/svg/install.svg';
import Maintenance from '../assets/svg/maintenance.svg';
import Phone from '../assets/svg/phone.svg';
import Address from '../assets/svg/address.svg';
import Email from '../assets/svg/email.svg';

export const svgs = {
    research: Research,
    build: Build,
    design: Design,
    install: Install,
    maintenance: Maintenance,
    phone: Phone,
    address: Address,
    email: Email
};